import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";
import PerfilHelpers from "./helpers/PerfilHelpers";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    if (
        location.path == this.history.current.path ||
        location.name == this.history.current.name
    )
        return;

    return originalPush.call(this, location).catch(err => {
        const error = err.message.toString().toLowerCase();
        if (error.indexOf("redirected") === -1 && error.indexOf("cancelled") === -1)
            throw err;
    });
};

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
        path: "/acesso",
        component: () =>
            import("@/layouts/full-page/FullPage.vue"),
        meta: {
            authRequired: false,
            canAccessLogged: false
        },
        children: [
            {
                path: "/login/:companyDns?",
                name: "page-login",
                component: () =>
                    import("@/views/acesso/Login.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/credentials",
                name: "page-credentials-sso",
                component: () =>
                    import("@/views/acesso/CredentialsSSO.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
              {
                path: "/acesso/credentials-asd/:token/:extraPath(.*)?",
                name: "page-credentials-asd",
                component: () =>
                    import("@/views/acesso/CredentialASD.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/acesso/credentials-asd/:token/:extraPath(.*)?",
                name: "page-credentials-asd",
                component: () =>
                    import("@/views/acesso/CredentialASD.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/login/:companyDns?/assessor",
                name: "page-login-assessor",
                component: () =>
                    import("@/views/acesso/LoginAdvisor.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/iniciar-cadastro",
                name: "page-iniciar-cadastro",
                component: () =>
                    import("@/views/cadastro/IniciarCadastro.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/completar-cadastro/:cnpj",
                name: "page-completar-cadastro",
                component: () =>
                    import("@/views/cadastro/CompletarCadastro.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/primeiro-acesso/:token",
                name: "page-primeiro-acesso",
                component: () =>
                    import("@/views/acesso/PrimeiroAcesso.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/recuperacao-da-senha",
                name: "page-recuperacao-da-senha",
                component: () =>
                    import("@/views/acesso/RecuperarSenha.vue"),
                meta: {
                    rule: "public",
                    authRequired: false,
                    canAccessLogged: false
                }
            },
            {
                path: "/alterar-senha/:token",
                name: "page-alterar-senha",
                component: () =>
                    import("@/views/acesso/AlteraSenha.vue"),
                meta: {
                    authRequired: false,
                    authRequired: false
                }
            },
            {
                path: "/error-401",
                name: "page-error-401",
                component: () =>
                    import("@/views/pages/Error401.vue"),
                meta: {
                    authRequired: false,
                    authRequired: false
                }
            },
            {
                path: "/maintenance",
                name: "page-error-503",
                component: () =>
                    import("@/views/pages/Maintenance.vue"),
                meta: {
                    rule: "public",
                    authRequired: false
                }
            },
            {
                path: "/error-404",
                name: "page-error-404",
                component: () =>
                    import("@/views/pages/Error404.vue"),
                meta: {
                    rule: "public",
                    authRequired: false
                }
            },
            {
                path: "/locked",
                name: "page-lock",
                component: () =>
                    import("@/views/acesso/LockScreen.vue"),
                meta: {
                    rule: "public",
                    authRequired: false
                }
            }
        ]
    },
    {
        path: "",
        redirect: { name: "redirect-to-home" },
        component: () =>
            import("./layouts/main/Main.vue"),
        meta: {
            authRequired: true
        },
        children: [{
            path: "/redirect-to-home",
            name: "redirect-to-home",
            component: () =>
                import("./views/Home.vue"),
            meta: {
                authRequired: true
            }
        },
        {
            path: "/contrato",
            name: "contrato",
            component: () =>
                import("./views/contrato/Contrato.vue"),
            meta: {
                rule: "administrator",
                authRequired: true
            }
        },
        {
            path: "/stepper",
            name: "stepper",
            component: () =>
                import("./views/stepper/Stepper.vue"),
            meta: {
                rule: "administrator",
                authRequired: true
            }
        },
        {
            path: "/bi/embbeded",
            name: "bi_embbeded",
            component: () =>
                import("./views/bi/Embbeded.vue"),
            meta: {
                permission: [PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_BI],
                authRequired: true
            }
        },
        {
            path: "/integracoes",
            name: "integracoes",
            component: () =>
                import("./views/consulta-integracoes/index.vue"),
            meta: {
                permission: [PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_INTEGRACOES],
                authRequired: true
            }
        },
        {
            path: "/gerenciamento-consultas-bureau",
            name: "gerenciamento-consultas-bureau",
            component: () =>
                import("./views/gerenciamento-consultas-bureau/GerenciamentoConsultasBureau.vue"),
            meta: {
                permission: [PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA],
                authRequired: true
            }
        },
        {
            path: "/parametrizacao",
            redirect: { name: "param-menu" },
            name: "parametrizacao",
            component: () =>
                import("./views/parametrizacao/Parametrizacao.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                ],
                authRequired: true
            },
            children: [{
                    path: "/parametrizacao/menu",
                    name: "param-menu",
                    component: () =>
                        import("@/views/parametrizacao/ListaDeParametrizacao.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/broker-profile",
                    name: "param-broker-profile",
                    component: () =>
                        import("@/views/parametrizacao/BrokerProfileList.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/modalidades",
                    name: "param-modalidades",
                    component: () =>
                        import("@/views/parametrizacao/ListaDeRamos.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/endosso",
                    name: "param-endosso-list",
                    component: () =>
                        import("@/views/parametrizacao/components/endosso/index.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/endosso/modalidades-aniversario",
                    name: "param-list-endosso",
                    component: () =>
                        import("@/views/parametrizacao/components/endosso/modalidades.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/endosso/configurar-aniversario",
                    name: "param-configurar-endosso",
                    component: () =>
                        import("@/views/parametrizacao/components/endosso/config.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/endosso/criar-modalidade-aniversario",
                    name: "param-criar-modalidade-aniversario",
                    component: () =>
                        import("@/views/parametrizacao/components/endosso/criarModalidade.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/endosso/editar-modalidade-aniversario/:token",
                    name: "param-editar-modalidade-aniversario",
                    component: () =>
                        import("@/views/parametrizacao/components/endosso/editarModalidade.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/renovacao",
                    name: "param-renovacao-list",
                    component: () =>
                        import("@/views/parametrizacao/components/renovacao/index.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/renovacao/modalidades-renovacao",
                    name: "param-list-renovacao",
                    component: () =>
                        import("@/views/parametrizacao/components/renovacao/modalidades.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/renovacao/configurar-renovacao",
                    name: "param-configurar-renovacao",
                    component: () =>
                        import("@/views/parametrizacao/components/renovacao/config.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/renovacao/criar-modalidade-renovacao",
                    name: "param-criar-modalidade-renovacao",
                    component: () =>
                        import("@/views/parametrizacao/components/renovacao/criarModalidade.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/renovacao/editar-modalidade-renovacao/:token",
                    name: "param-editar-modalidade-renovacao",
                    component: () =>
                        import("@/views/parametrizacao/components/renovacao/editarModalidade.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/consulta-pj",
                    name: "param-consulta-pj",
                    component: () =>
                        import("@/views/parametrizacao/Consulta.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/branch/:branchId/grupo/:modalityGroupId/modalidade/:modalidadeId/:modalidadeUniqueId/",
                    name: "param-configurar-modalidade",
                    component: () =>
                        import("./views/parametrizacao/ConfigurarModalidade.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/branch/:branchId/modalidade/:modalityGroupId",
                    name: "param-detalhar-modalidade",
                    component: () =>
                        import("./views/parametrizacao/ConfigurarModalidade.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/condicoes-gerais",
                    name: "param-condicoes",
                    component: () =>
                        import("@/views/parametrizacao/CondicoesGerais"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/parametrizacao/pagamentos",
                    name: "param-pagamento",
                    component: () =>
                        import("@/views/parametrizacao/Pagamento"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                        ],
                        authRequired: true
                    }
                },
                {
                path: "/parametrizacao/bureaus-consulta",
                name: "param-bureaus-consulta",
                component: () =>
                    import("@/views/parametrizacao/BureausConsulta.vue"),
                meta: {
                    permission: [
                        PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                    ],
                    authRequired: true
                },
                },
                {
                path: "/parametrizacao/bureaus-consulta/:tipoConsultaId",
                name: "parametrizacao-bureaus",
                component: () =>
                    import("@/views/parametrizacao/components/bureau/ParametrizacaoBureau.vue"),
                meta: {
                    permission: [
                        PerfilHelpers.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                    ],
                    authRequired: true
                }
                }
            ]
        },
        {
            path: "/apolices/endossos",
            name: "solicitacao-endosso-apolice",
            redirect: { name: "endosso-alteracao" },
            component: () =>
                import("./views/endosso/SolicitacaoDeEndosso.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO
                ],
                authRequired: true
            },
            children: [{
                    path: "/apolices/endossos/alteracao/:policyUniqueId/:typeId",
                    name: "endosso-alteracao",
                    component: () =>
                        import("@/views/endosso/EndossoAlteracao.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/apolices/endossos/cancelamento/:policyUniqueId/:typeId",
                    name: "endosso-cancelamento",
                    component: () =>
                        import("@/views/endosso/EndossoCancelamento.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/apolices/endossos/emissao/:uniqueId",
                    name: "endosso-emissao",
                    component: () =>
                        import("@/views/endosso/EditarEndosso.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO
                        ],
                        authRequired: true
                    }
                }
            ]
        },
        {
            path: "/kanban",
            name: "kanban",
            component: () =>
                import("./views/kanban/ListPipes.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_ESTEIRAS,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_CADASTRO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_CREDITO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_NOMEACAO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_PEP,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_RESSEGURO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_SUBSCRICAO
                ],
                authRequired: true
            }
        },
        {
            path: "/kanban/pipe/:token/:pipeid?",
            name: "kanban-pipe",
            component: () =>
                import("@/views/kanban/Pipe.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_ESTEIRAS,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_CADASTRO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_CREDITO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_NOMEACAO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_PEP,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_RESSEGURO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PIPE_SUBSCRICAO
                ],
                authRequired: true
            }
        },
        {
            path: "/apolices",
            name: "apolices",
            component: () =>
                import("./views/apolices/ListarApolices.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_APOLICES
                ],
                authRequired: true
            }
        },
        {
            path: "/apolice/:policyUniqueId",
            name: "detalhar-apolice",
            component: () =>
                import("./views/apolices/Apolice.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_APOLICES
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas",
            name: "propostas",
            component: () =>
                import("./views/propostas/ListarPropostas.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_PROPOSTAS,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas/proposta/:propostaUniqueId",
            name: "detalhe-proposta",
            component: () =>
                import("./views/propostas/CriarProposta.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas/proposta/editar/:propostaUniqueId",
            name: "editar-proposta",
            component: () =>
                import("./views/propostas/EditarProposta.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas/timeline/:propostaUniqueId",
            name: "timeline-proposta",
            component: () =>
                import("./views/propostas/TimelineProposta.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_PROPOSTAS,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas/nova-proposta",
            name: "nova-proposta",
            component: () =>
                import("./views/propostas/CriarProposta.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS
                ],
                authRequired: true
            }
        },
        {
            path: "/corretor",
            name: "corretor",
            component: () =>
                import("./views/perfil/PerfilCorretora.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORA,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORA
                ],
                authRequired: true
            }
        },
        {
            path: "/corretor-assessoria",
            name: "corretor-assessoria",
            component: () =>
                import("./views/corretor/ListarCorretorAssessor.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.LISTAR_CORRETORES_ASSESSOR
                ],
                authRequired: true
            }
        },
        {
            path: "/documentos",
            name: "documentos",
            component: () =>
                import("./views/documentos/Documentos.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_DOCUMENTOS,
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_DOCUMENTOS
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas-seguradora",
            name: "propostas-seguradora",
            component: () =>
                import("./views/propostas/ListarPropostaSeguradora.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                    PerfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_PROPOSTAS_SEGURADORA
                ],
                authRequired: true
            }
        },
        {
            path: "/propostas-seguradora/proposta/:proposalType?/:propostaUniqueId?",
            name: "resumo-proposta-visao-seguradora",
            component: () =>
                import("./views/propostas/VisualizarPropostaSeguradora.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                    PerfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_PROPOSTAS_SEGURADORA
                ],
                authRequired: true
            }
        },
        {
            path: "/corretores",
            name: "corretores",
            component: () =>
                import("./views/corretor/ListarCorretor.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORES,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
                ],
                authRequired: true
            }
        },
        {
            path: "/corretor/:corretorUniqueId",
            name: "detalhe-corretor",
            component: () =>
                import("./views/corretor/DetalheCorretor.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORES,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
                ],
                authRequired: true
            }
        },
        {
            path: "/corretores/cadastrar-novo-corretor",
            name: "cadastrar-novo-corretor",
            component: () =>
            import ("./views/cadastro-corretor-seguradora/CadastroCorretorSeguradora.vue"),
            meta: {
            permission: [
                PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORES,
                PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
            ],
            authRequired: true
            }
        },
        {
            path: "/tomadores",
            name: "tomadores",
            component: () =>
                import("./views/tomador/ListarTomador.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES,
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_TOMADORES,
                    PerfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_TOMADORES_SEGURADORA,
                    PerfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_TOMADORES_SEGURADORA
                ],
                authRequired: true
            }
        },
        {
            path: "/segurados",
            name: "segurados",
            component: () =>
                import("./views/segurado/ListarSegurado.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_SEGURADO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
                ],
                authRequired: true
            }
        },
        {
            path: "/segurado/:seguradoUniqueId",
            name: "detalhe-segurado",
            component: () =>
                import("./views/segurado/DetalheSegurado.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_SEGURADO,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
                ],
                authRequired: true
            }
        },
        {
            path: "/tomador/:tomadorUniqueId",
            name: "detalhe-tomador",
            component: () =>
                import("./views/tomador/DetalheTomador.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES,
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_TOMADORES,
                    PerfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_TOMADORES_SEGURADORA,
                    PerfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_TOMADORES_SEGURADORA
                ],
                authRequired: true
            }
        },
        {
            path: "/usuarios",
            name: "usuarios",
            component: () =>
                import("@/views/usuarios/UsuariosMenu.vue"),
            meta: {
                permission: [
                    PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS,
                    PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PERFIS
                ],
                authRequired: true
            },
            children: [{
                    path: "/usuarios/listar",
                    name: "lista-usuarios",
                    component: () =>
                        import("@/views/usuarios/ListarUsuario.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
                            PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
                        ],
                        authRequired: true
                    }
                },
                {
                    path: "/usuarios/perfis",
                    name: "lista-perfis",
                    component: () =>
                        import("@/views/usuarios/ListarPerfil.vue"),
                    meta: {
                        permission: [
                            PerfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PERFIS
                        ],
                        authRequired: true
                    }
                }
            ]
        },
        {
            path: "/termos-aceite",
            name: "termosDeAceite",
            component: () =>
                import("@/views/termos-de-aceite/TermosDeAceite.vue"),
            meta: {
                authRequired: true
            }
        },
        {
            path: "/propostas/nova-proposta-new",
            name: "nova-proposta-new",
            redirect: { path: "/propostas/nova-proposta-new/dados-tomador" },
            component: () =>
                import("@/views/propostas-new/NovaProposta.vue"),
            meta: {
                authRequired: true
            },
            children: [
                {
                    path: "/propostas/nova-proposta-new/dados-tomador",
                    name: "nova-proposta-dados-tomador",
                    component: () =>
                        import("@/views/propostas-new/criacao/dadosTomador/"),

                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "/propostas/nova-proposta-new/dados-segurado",
                    name: "nova-proposta-dados-segurado",
                    component: () =>
                        import("@/views/propostas-new/criacao/dadosSegurado/"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "/propostas/nova-proposta-new/dados-de-risco",
                    name: "nova-proposta-dados-de-risco",
                    component: () =>
                        import("@/views/propostas-new/criacao/dadosRisco/"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "/propostas/nova-proposta-new/resumo-da-proposta",
                    name: "nova-proposta-resumo-da-proposta",
                    component: () =>
                        import("@/views/propostas-new/criacao/resumoDaProposta/"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "/propostas/nova-proposta-new/dados-financeiros/:propostaUniqueId",
                    name: "nova-proposta-dados-financeiros",
                    component: () =>
                        import("@/views/propostas-new/criacao/dadosFinanceiros/"),
                    meta: {
                        authRequired: true
                    }
                }                   
            ]
        },
        {
            path: "/propostas/endosso-new/cancelamento/:policyUniqueId/:subTipoEndossoId",
            name: "endosso-cancelamento-new",
            redirect: { path: "/propostas/endosso-new/cancelamento/:policyUniqueId/:subTipoEndossoId/informacoes-do-endosso" },
            component: () =>
                import("@/views/endossos-new/Cancelamento.vue"),
            meta: {
                authRequired: true
            },
            children: [
                {
                    path: "informacoes-do-endosso",
                    name: "cancelamento-informacoes-do-endosso",
                    component: () =>
                        import("@/views/endossos-new/criacao/informacoes-endosso/cancelamento/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "motivo-e-documentos",
                    name: "cancelamento-motivo-e-documentos",
                    component: () =>
                        import("@/views/endossos-new/criacao/motivo-documentos/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "resumo-da-alteracao",
                    name: "cancelamento-resumo-da-alteracao",
                    component: () =>
                        import("@/views/endossos-new/criacao/resumo-alteracoes/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "informacoes-de-pagamento",
                    name: "cancelamento-informacoes-de-pagamento",
                    component: () =>
                        import("@/views/endossos-new/criacao/informacoes-pagamento/index.vue"),
                    meta: {
                        authRequired: true
                    }
                }                  
            ]
        },
        {
            path: "/propostas/endosso-new/alteracao/:policyUniqueId/:typeId",
            name: "endosso-alteracao-new",
            redirect: { path: "/propostas/endosso-new/alteracao/:policyUniqueId/:typeId/informacoes-do-endosso" },
            component: () =>
                import("@/views/endossos-new/Alteracao.vue"),
            meta: {
                authRequired: true
            },
            children: [
                {
                    path: "informacoes-do-endosso",
                    name: "alteracao-informacoes-do-endosso",
                    component: () =>
                        import("@/views/endossos-new/criacao/informacoes-endosso/alteracao/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "motivo-e-documentos",
                    name: "alteracao-motivo-e-documentos",
                    component: () =>
                        import("@/views/endossos-new/criacao/motivo-documentos/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "resumo-da-alteracao",
                    name: "alteracao-resumo-da-alteracao",
                    component: () =>
                        import("@/views/endossos-new/criacao/resumo-alteracoes/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "informacoes-de-pagamento",
                    name: "alteracao-informacoes-de-pagamento",
                    component: () =>
                        import("@/views/endossos-new/criacao/informacoes-pagamento/index.vue"),
                    meta: {
                        authRequired: true
                    }
                }                  
            ]
        },
        {
            path: "/propostas/endosso-new/neutro/:policyUniqueId/:typeId",
            name: "endosso-neutro-new",
            redirect: { path: "/propostas/endosso-new/neutro/:policyUniqueId/:typeId/informacoes-do-endosso" },
            component: () =>
                import("@/views/endossos-new/Neutro"),
            meta: {
                authRequired: true
            },
            children: [
                {
                    path: "informacoes-do-endosso",
                    name: "neutro-informacoes-do-endosso",
                    component: () =>
                        import("@/views/endossos-new/criacao/informacoes-endosso/neutro/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "motivo-e-documentos",
                    name: "neutro-motivo-e-documentos",
                    component: () =>
                        import("@/views/endossos-new/criacao/motivo-documentos/index.vue"),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: "resumo-da-alteracao",
                    name: "neutro-resumo-da-alteracao",
                    component: () =>
                        import("@/views/endossos-new/criacao/resumo-alteracoes/index.vue"),
                    meta: {
                        authRequired: true
                    }
                }                 
            ]
        },
        {
            path: "/propostas/timeline-new/:propostaUniqueId",
            name: "timeline-new",           
            component: () =>
                import("@/views/propostas-new/Timeline.vue"),
            meta: {
                authRequired: true
            },
        }
    ]},
    {
        path: "*",
        redirect: "/error-404"
    }
    ]
});

router.afterEach((to, from) => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");

    if (to.name == "page-login") {
        if (appLoading) {
            document.body.className = document.body.className.replace("noscroll", "");
            appLoading.style.display = "none";
        }
    } else if (appLoading) {
        document.body.className = document.body.className.replace("noscroll", "");
        appLoading.style.display = "none";
    }
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters["auth/isAuthenticated"];

    if (to.matched && to.matched.length > 0) {
        const meta = (to.matched[0] || {}).meta || {};

        if (
            meta.authRequired &&
            !isAuthenticated &&
            to.name !== "page-login"
        ) {
            return next({ name: "page-login" });
        }

        if ((to.meta || {}).canAccessLogged == false && isAuthenticated) {
            return next({ name: "redirect-to-home" });
        }

        if (to.name == "redirect-to-home")
            return next({ name: store.getters["menuHome"] });
    } else return next({ name: "page-error-404" });

    if (
        ((to.meta || {}).authRequired &&
            !!(to.meta || {}).permission &&
            PerfilHelpers.checkPermission((to.meta || {}).permission)) ||
        ((to.meta || {}).authRequired &&
            !((to.meta || {}).permission || []).length) ||
        (to.meta || {}).authRequired == false
    )
        return next();
    else return next({ name: "page-error-401" });
});

export default router;
