<template>
  <div
    class="container-element lista-auto-inputs"
    v-if="input.Type !== 'Repeater'"
  >
    <p class="label-content label">{{ input.Label }}</p>
    <ValidationProvider
      :rules="validatorRuleObject"
      :name="input.Label"
      v-slot="{ errors }"
    >
      <jodit-editor
        :config="joditConfig"
        v-if="input.Type === 'TextArea'"
        v-model="input.Value"
        @input="emitFunction('input', $event)"
        @change="emitFunction('change', $event)"
      />

      <datepicker
        v-else-if="input.Type === 'Date'"
        :language="ptBR"
        class="w-full"
        format="dd/MM/yyyy"
        :name="input.Name"
        v-model="input.Value"
        :data-label="input.Label"
        @input="emitFunction('input', $event)"
        @change="emitFunction('change', $event)"
      />

      <currency-input
        :maxlength="50"
        v-else-if="input.Type === 'Money'"
        class="w-full vs-inputx vs-input--input large hasValue"
        :name="input.Name"
        v-model="input.Value"
        :data-label="input.Label"
        @input="emitFunction('input', $event)"
        @change="emitFunction('change', $event)"
      />

      <vs-input
        :maxlength="50"
        v-else-if="input.Type === 'Number'"
        class="w-full"
        size="large"
        :name="input.Name"
        v-model.number="input.Value"
        :data-label="input.Label"
        @input="emitFunction('input', $event)"
        @change="emitFunction('change', $event)"
      />

      <v-select
        v-else-if="input.Type === 'Select'"
        size="large"
        :value="input.Value"
        @input="emitFunction('input', $event)"
        @change="emitFunction('change', $event)"
        :options="getChoices(input.Choices)"
        :name="input.Name"
        placeholder=""
        class="w-full"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      />

      <vs-input
        v-else
        class="w-full"
        size="large"
        :name="input.Name"
        v-model="input.Value"
        :disabled="disabledTagFieldsEndosso"
        :maxlength="50"
        :data-label="input.Label"
        @input="emitFunction('input', $event)"
        @change="emitFunction('change', $event)"
      />

      <span class="text-danger text-sm" v-show="errors[0]">
        {{ errors[0] }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import { ptBR } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { Jodit, JoditEditor } from "jodit-vue";
export default {
  name: "onpoint-input",
  components: { quillEditor, Datepicker },
  props: {
    input: Object,
    handleFunction: String,
    validatorRuleObject: Object,
    disabledTagFieldsEndosso: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { ptBR, joditConfig: { language: "pt_br" } };
  },
  created() {
    this.setupEditor();
  },
  methods: {
    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        "Arial Narrow": "Arial Narrow",
        Calibri: "Calibri",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto"
      };
      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        16: "16",
        18: "18",
        19: "19",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90"
      };
    },
    handler() {
      this.$emit("handleFunction");
      return {};
    },

    getChoices(options) {
      return eval(options);
    },

    emitFunction(event, value) {
      this.$emit(event, value);
    }
  }
};
</script>
