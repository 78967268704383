import instance from "@/axios";
import endossoProvider from "@/providers/endosso-provider";

export default {
  namespaced: true,
  state: {
    apoliceOriginalEndosso: {},
    endosso: {
      PolicyUniqueId: null,
      DataCancelamento: null,
      ChangeTypes: [],
      PremioEndosso: {
        InsurancePremium: null,
        InsurancePremiumPrincipalModality: null,
        InsurancePremiumComplementarModality: null,
        Cobertura: [],
      },
      MotivoId: null,
      Objeto: [],
      Documentos: [],
      DadosBancarios: {},
    },
    parametrosEndossoCancelamento: {},
  },
  mutations: {
    updateApoliceOriginalEndosso(state, data) {
      state.apoliceOriginalEndosso = data;
    },

    updateEndosso(state, data) {
      state.endosso = data;
    },

    updateDataCancelamento(state, data) {
      state.endosso.DataCancelamento = data;
    },

    updateParametrosEndossoCancelamento(state, data) {
      state.parametrosEndossoCancelamento = data;
    },

    updateInsurancePremium(state, data) {
      state.endosso.PremioEndosso.InsurancePremium = data;
    },

    updateInsurancePremiumPrincipalModality(state, data) {
      state.endosso.PremioEndosso.InsurancePremiumPrincipalModality = data;
    },

    updateInsurancePremiumComplementarModality(state, data) {
      state.endosso.PremioEndosso.InsurancePremiumComplementarModality = data;
    },

    updateCobertura(state, data) {
      state.endosso.PremioEndosso.Cobertura = data;
    },
  },
  actions: {},
  getters: {
    apoliceOriginalEndosso(state) {
      return state.apoliceOriginalEndosso || {};
    },

    endosso(state) {
      return state.endosso || {};
    },

    parametrosEndossoCancelamento(state) {
      return state.parametrosEndossoCancelamento || {};
    },
  },
};
