import instance from "@/axios";

class EndossoProvider {
  async obterPolicyUniqueIdPorNumeroApolice(numeroApolice) {
    return await instance
      .get(
        `api/Policy/ObterPolicyUniqueIdPorNumeroApolice?numeroApolice=${numeroApolice}`
      )
      .then((response) => response.data.Response);
  }

  async validarEndossoCancelamento(policyUniqueId) {
    return await instance
      .post(
        `api/Endosso/ValidarEndossoCancelamento?policyUniqueId=${policyUniqueId}`
      )
      .then((response) => response.data.Response);
  }

  async obterDadosEndossoCancelamento(payload) {
    return await instance
      .post("/api/Endosso/ObterDadosEndossoCancelamento", payload)
      .then((response) => response.data.Response);
  }

  async obterParametrosEndossoCancelamento(policyUniqueId) {
    return await instance
      .get(
        `/api/Endosso/ObterParametrosEndossoCancelamento?policyUniqueId=${policyUniqueId}`
      )
      .then((response) => response.data.Response);
  }

  async calcularPremioCancelamento(payload) {
    return await instance
      .post(
        `/api/Endosso/CalcularPremioCancelamento`, payload
      )
      .then((response) => response.data.Response);
  }

  async obterBancos() {
    return await instance
      .get(`/api/Core/GetBanks`)
      .then((response) => response.data.Response);
  }

  async obterTiposContasBancarias() {
    return await instance
      .get(`api/Core/GetBankAccountTypes`)
      .then((response) => response.data.Response);
  }

  async obterContasBancariasTomador(policyHolderUniqueId) {
    return instance
      .get(
        `/api/Core/GetPersonBankAccounts?personUniqueId=${policyHolderUniqueId}&lastBankAccount=false`
      )
      .then((response) => response.data.Response);
  }
}

export default new EndossoProvider();
